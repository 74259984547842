import { ComponentProps } from "react";

import FullWidthImageWithTitle from "components/templates/FullWidthImageWithTitle";

export const FULL_WIDTH_IMAGE_WITH_TITLE_PROPS: Readonly<
  ComponentProps<typeof FullWidthImageWithTitle>
> = {
  image: {
    src: "/images/apply/gallery.webp",
    alt: "Success stories",
    unoptimized: true,
  },
  color: "text-green-gray",
  spacing: [
    {
      step: 24,
      side: "y axis",
      type: "padding",
      breakpoint_prefix: "md",
    },
    {
      step: 16,
      side: "y axis",
      type: "padding",
    },
  ],
};
