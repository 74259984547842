import clsx from "clsx";
import { ComponentProps } from "react";

import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";
import TipCard, { TipCardCompoundProps } from "components/molecules/TipCard";

import classnames, { Spacing } from "lib/classnames";

import { BackgroundColors, TextColors } from "types/theme.types";

interface Props {
  bg: BackgroundColors;
  color: TextColors;
  spacing: Spacing[];
  title: string;
  cards: {
    className: string;
    list: TipCardCompoundProps[];
  };
  content?: {
    paragraph: string;
    paragraphBold?: string;
  }[];
  contentColor?: TextColors;
  titleAs?: keyof typeof Typography;
}

interface TipCardTitleProps {
  color: TextColors;
  className?: string;
  children: React.ReactNode;
  as: Props["titleAs"];
}

function TipCardTitle(props: TipCardTitleProps) {
  const Title = Typography[props.as ?? "H2"];
  return <Title {...props}>{props.children}</Title>;
}

export default function TipCardsWithTitle(props: Props) {
  const sectionClassname = clsx(classnames.spacing(props.spacing), props.bg);
  return (
    <>
      <Section className={sectionClassname}>
        {props.title && (
          <TipCardTitle
            as={props.titleAs}
            color={props.color}
            className="mx-auto text-center md:px-11"
          >
            {props.title}
          </TipCardTitle>
        )}
        {props.content?.length > 0 && (
          <div className="mt-8">
            {props.content.map(({ paragraph, paragraphBold }, index) => (
              <div key={index}>
                <Typography.BodyLg
                  className={clsx(
                    props.contentColor,
                    "mx-auto",
                    "text-center",
                    "text-warm-light",
                    "md:px-11"
                  )}
                >
                  {paragraph}
                </Typography.BodyLg>
                {paragraphBold && (
                  <Typography.BodyLg
                    className={clsx(
                      props.contentColor,
                      "mx-auto",
                      "text-center",
                      "text-warm-light",
                      "md:px-11",
                      "font-semibold"
                    )}
                  >
                    {paragraphBold}
                  </Typography.BodyLg>
                )}
              </div>
            ))}
          </div>
        )}
        <div className={clsx(props.cards.className)}>
          {props.cards.list.map((card) => (
            <TipCard
              key={card.title}
              className="space-y-4"
              {...(card as ComponentProps<typeof TipCard>)}
            >
              <div
                className={clsx(
                  "flex items-center",
                  card.icon?.stacked && "md:block"
                )}
              >
                {card.icon && (
                  <TipCard.Icon
                    icon={card.icon.className}
                    color={card.color}
                    className="text-[40px] pb-2 pr-4 align-middle"
                  />
                )}
                <TipCard.Title color={card.color} as={card.titleAs ?? "H4"}>
                  {card.title}
                </TipCard.Title>
              </div>

              {card.paragraph && (
                <TipCard.Body color={card.contentColor}>
                  {card.paragraph}
                </TipCard.Body>
              )}
            </TipCard>
          ))}
        </div>
      </Section>
    </>
  );
}
