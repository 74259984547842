import clsx from "clsx";
import Link from "next/link";

import Typography from "components/atoms/Typography";

import { BackgroundColors, TextColors } from "types/theme.types";

interface Props {
  title: string;
  titleColor: TextColors;
  titleClassName?: string;
  detail: string;
  detailColor: TextColors;
  bgColor: BackgroundColors;
  link?: string;
}

export default function LearnMoreCard(props: Props) {
  const cardClassName = clsx(
    "flex",
    "flex-1",
    "flex-col",
    "items-center",
    "p-6",
    "rounded-2xl",
    props.bgColor
  );

  const contentClassName = clsx(
    "italic",
    "px-8",
    "text-center",
    "pb-3",
    props.detailColor
  );

  return (
    <div className={cardClassName}>
      <Typography.H5
        className={clsx("font-medium", props.titleClassName)}
        color={props.titleColor}
      >
        {props.title}
      </Typography.H5>
      <Typography.BodySm className={contentClassName}>
        {props.detail}
      </Typography.BodySm>
      {props.link && (
        <Link href={props.link}>
          <a className="mt-auto">
            <Typography.BodyLg
              className={clsx("items-center", "flex", props.detailColor)}
            >
              Learn More <i className="fa fa-arrow-right ml-2 fa-sm" />
            </Typography.BodyLg>
          </a>
        </Link>
      )}
    </div>
  );
}
