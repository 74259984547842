import { ComponentProps } from "react";

import TipCardsWithTitle from "components/templates/TipCardsWithTitle";

export const TIP_CARDS_WITH_TITLE_PROPS: Readonly<
  ComponentProps<typeof TipCardsWithTitle>
> = {
  titleAs: "H1",
  bg: "bg-white",
  color: "text-sv-red",
  title: "Why SV Academy",
  contentColor: "text-green-black",
  content: [
    {
      paragraph:
        "We’ve helped thousands of people just like you start on a path toward high-growth careers. SV Academy is more than a bootcamp– we provide everything you need to launch a successful business career.",
    },
  ],
  spacing: [
    {
      type: "padding",
      side: "top",
      step: 20,
    },
  ],
  cards: {
    className: "grid grid-cols-1 md:grid-cols-3 mt-8 md:mt-12 gap-4 md:gap-11",
    list: [
      {
        titleAs: "H5",
        bg: "bg-warm-light",
        color: "text-green-gray",
        contentColor: "text-green-black",
        title: "Thousands of lives changed",
        paragraph:
          "We’ve helped over 3000 graduates from diverse and underrepresented backgrounds launch new business careers through high-growth sales.",
        icon: {
          className: "fak fa-sv-trained md:pb-8 -ml-1.5",
          stacked: true,
        },
      },
      {
        titleAs: "H5",
        bg: "bg-warm-light",
        color: "text-green-gray",
        contentColor: "text-green-black",
        title: "Business leaders hire from us first",
        paragraph:
          "Unlike other bootcamps, we’re experts in high-growth sales and business training, which is why 800+ employers across industries trust us to provide top performing talent.",
        icon: { className: "fak fa-sv-stairs md:pb-8", stacked: true },
      },
      {
        titleAs: "H5",
        bg: "bg-warm-light",
        color: "text-green-gray",
        contentColor: "text-green-black",
        title: "More than a training program or bootcamp",
        paragraph:
          "SV Academy gives you everything you need to launch a new career:  hands-on intensive training, a powerful community, career transition support, and professional coaching.",
        icon: {
          className: "fak fa-sv-icon-group md:pb-8",
          stacked: true,
        },
      },
    ],
  },
};
