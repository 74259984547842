import { ComponentProps } from "react";

import CardsWithDisclaimer from "components/templates/CardsWithDisclaimer";

export const CARDS_WITH_DISCLAIMER_PROPS: Readonly<
  ComponentProps<typeof CardsWithDisclaimer>
> = {
  title: "Our results speak for themselves.",
  gridColsClassName: "md:grid-cols-2",
  cards: [
    {
      title: "$70,000",
      paragraph: "SV Academy grads' average starting income",
    },
    {
      title: "67 days",
      paragraph: "Average time it takes for SV Academy grads to get hired",
    },
    {
      title: "60%",
      paragraph:
        "Percentage of SV Academy graduates that get promoted within 1 year",
    },
    {
      title: "3000+",
      paragraph: "Over 3000 job seekers have graduated from SV Academy",
    },
  ],
  disclaimer: {
    text: "Results based on a study of hired graduates. Please remember that past performance may not be indicative of future results. Your actual results may differ from results reported for many reasons. To be eligible for admission to SV Academy, applicants must 1) be a U.S. citizen or legal permanent resident, 2) complete an online application, and 3) complete and pass a written assessment.",
    className: "text-green-gray",
  },
};
