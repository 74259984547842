import clsx from "clsx";

import Typography from "components/atoms/Typography";

import { TextColors } from "types/theme.types";

interface Props {
  items: {
    title?: string;
    markup?: string;
    description?: string;
  }[];
  className?: string;
  color: TextColors;
}

export default function Checklist(props: Props) {
  const containerClassName = clsx("list-inside", "space-y-4", props.className);
  return (
    <ul className={containerClassName}>
      {props.items.map((item) => (
        <li className="flex space-x-2" key={item.title}>
          <i className={clsx("fak fa-sv-checklist text-2xl", props.color)}></i>
          <Typography.BodyLg className="text-white">
            {item.markup ? (
              <span dangerouslySetInnerHTML={{ __html: item.markup }} />
            ) : (
              item.title
            )}
            {item.description && (
              <span className={clsx(props.color, "italic block font-charter")}>
                {item.description}
              </span>
            )}
          </Typography.BodyLg>
        </li>
      ))}
    </ul>
  );
}
