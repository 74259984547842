import clsx from "clsx";

import Typography, { TypographyProps } from "components/atoms/Typography";

import { BackgroundColors, TextColors } from "types/theme.types";

export interface TipCardContainerProps {
  bg: BackgroundColors;
  children?: React.ReactNode;
  className?: string;
}

export interface TipCardCompoundProps extends TipCardContainerProps {
  title: string;
  paragraph?: string;
  color: TextColors;
  contentColor: TextColors;
  icon?: {
    stacked?: boolean;
    className?: string;
  };
  titleAs?: keyof typeof Typography;
}

function TipCard(props: TipCardContainerProps) {
  const cardClassName = clsx(
    "flex",
    "flex-col",
    "items-start",
    "p-6",
    "md:p-8",
    "rounded-2xl",
    props.bg,
    props.className
  );

  return <div className={cardClassName}>{props.children}</div>;
}

interface TipCardTitleProps extends TypographyProps {
  as?: keyof typeof Typography;
  className?: string;
  children?: React.ReactNode;
}

interface TipCardBodyProps extends TypographyProps {
  className?: string;
  children?: React.ReactNode;
}

interface TipCardIconProps {
  className?: string;
  color?: TextColors;
  icon: string;
}

const TipCardTitle = (props: TipCardTitleProps) => {
  const Title = Typography[props.as ?? "H4"];
  return <Title {...props}>{props.children}</Title>;
};

const TipCardBody = (props: TipCardBodyProps) => {
  return <Typography.Body {...props}>{props.children}</Typography.Body>;
};

const TipCardIcon = (props: TipCardIconProps) => {
  return <i className={clsx(props.icon, props.color, props.className)}></i>;
};
TipCard.Title = TipCardTitle;
TipCard.Body = TipCardBody;
TipCard.Icon = TipCardIcon;

export default TipCard;
