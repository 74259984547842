import clsx from "clsx";
import { ReactNode } from "react";

import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";
import CTAButton from "components/molecules/CTAButton";

import classnames from "lib/classnames";

import { CTA } from "types/common.types";
import { TextColors } from "types/theme.types";

export interface Card {
  title: string;
  paragraph: string;
}

interface TitleProps {
  children: ReactNode;
  color?: TextColors;
  className?: string;
}
function Title(props: TitleProps) {
  return (
    <Typography.H2
      className={clsx(
        "text-center max-w-3xl mx-auto mb-8 md:mb-12",
        props.color,
        props.className
      )}
    >
      {props.children}
    </Typography.H2>
  );
}

interface GridProps {
  children: ReactNode;
  columns: number;
  className?: string;
}
function Grid(props: GridProps) {
  return (
    <div
      className={clsx(
        "grid grid-cols-1 gap-4 mb-8 md:gap-11 md:mb-12 md:items-stretch",
        `md:grid-cols-${props.columns}`,
        props.className
      )}
    >
      {props.children}
    </div>
  );
}

interface CardProps {
  children: ReactNode;
  className?: string;
}
function Card(props: CardProps) {
  return (
    <div
      className={clsx(
        "bg-green-black py-6 px-5 rounded-3xl md:p-8",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
interface Props {
  title?: string;
  className?: string;
  footer?: string;
  cards?: Card[];
  callToAction?: CTA;
  gridColsClassName?: string;
  disclaimer?: {
    text: string;
    className?: string;
  };
  children?: ReactNode;
}
function CardsWithDisclaimer(props: Props) {
  return (
    <Section className={clsx("bg-white py-16", props.className)}>
      {!props.children && props.title && (
        <Typography.H2 className="text-center text-green-black max-w-3xl mx-auto mb-8 md:mb-12">
          {props.title}
        </Typography.H2>
      )}
      {!props.children && (
        <Grid columns={2}>
          {props.cards.map((card) => (
            <Card key={card.title}>
              <Typography.H2 className="mb-4 text-green-bright !font-medium">
                {card.title}
              </Typography.H2>
              <Typography.Body className="text-white">
                {card.paragraph}
              </Typography.Body>
            </Card>
          ))}
        </Grid>
      )}
      {props.children}
      {props.footer && (
        <Typography.H4 className="text-green-black text-center">
          {props.footer}
        </Typography.H4>
      )}
      {props.callToAction && (
        <div className="mt-8 h-15 max-w-sm w-full mx-auto">
          <CTAButton
            className={classnames.salesButton()}
            callToAction={props.callToAction}
          />
        </div>
      )}
      {props.disclaimer && (
        <div className={clsx("md:px-14", props.disclaimer.className)}>
          <Typography.BodySm>{props.disclaimer.text}</Typography.BodySm>
        </div>
      )}
    </Section>
  );
}

CardsWithDisclaimer.Title = Title;
CardsWithDisclaimer.Grid = Grid;
CardsWithDisclaimer.Card = Card;

export default CardsWithDisclaimer;
