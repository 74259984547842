import clsx from "clsx";
import Image, { ImageProps } from "next/image";

import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";
import UnoptimizedImage from "components/atoms/UnoptimizedImage";

import classnames, { Spacing } from "lib/classnames";

interface Props {
  image: Partial<ImageProps>;
  title?: string;
  color: string;
  spacing?: Spacing[];
}

function FullWidthImageWithTitle(props: Props) {
  const titleClassname = clsx(
    "mx-auto",
    "text-center",
    "md:px-11",
    props.color,
    props.spacing
  );

  const ImageComponent = props.image?.unoptimized ? UnoptimizedImage : Image;
  const className = classnames.spacing(props.spacing);

  return (
    <div className={className}>
      {props.title && (
        <Section className="pt-16 pb-4 mb-6 md:pt-24 md:mb-12">
          <Typography.H2 className={titleClassname}>
            {props.title}
          </Typography.H2>
        </Section>
      )}
      <div className="w-full flex justify-center h-[500px] md:h-[868px] overflow-hidden relative">
        {props.image?.src && (
          <ImageComponent
            src={props.image.src}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt={props.image.alt}
          />
        )}
      </div>
    </div>
  );
}

export default FullWidthImageWithTitle;
