import { ComponentProps } from "react";

import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";
import Checklist from "components/organisms/Checklist";

type Checklist = ComponentProps<typeof Checklist>["items"];

interface Props {
  title?: string;
  paragraphs?: string[];
  checklists: [Checklist, Checklist]; // Only two checklists supported
}

export default function FullFrameBulletsWithTitle(props: Props) {
  return (
    <Section className="bg-green-black py-16 px-2 md:py-24">
      {props.title && (
        <Typography.H1 className="text-center text-green-bright mb-6 md:mb-16 mx-auto md:px-11">
          {props.title}
        </Typography.H1>
      )}
      {props.paragraphs?.map((paragraph) => (
        <Typography.H5
          key={paragraph}
          className="text-white mb-6 md:mb-16 mx-auto"
        >
          {paragraph}
        </Typography.H5>
      ))}
      <div className="flex flex-col md:flex-row md:gap-12">
        {props.checklists.map((checklist) => (
          <Checklist
            key={checklist[0].title}
            color="text-green-pale"
            className="flex-1 first:mt-0 mt-4 md:mt-0"
            items={checklist}
          />
        ))}
      </div>
    </Section>
  );
}
