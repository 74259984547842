import { ComponentProps } from "react";

import HeroWithForm from "components/templates/HeroWithForm";

export const HERO_WITH_FORM_PROPS: Readonly<
  ComponentProps<typeof HeroWithForm>
> = {
  bg: "bg-warm-light",
  form: "applyStepOne",
  formBg: "bg-white",
  formSubmittedBg: "bg-white",
  title: "Build a career you can be proud of",
  subtitle:
    "Big goals require bold moves. Train for the job you need to pay for the lifestyle you want and build a life-changing business career—all in one place!",
  titleColor: "text-sv-red",
  showLogo: false,
  subtitleColor: "text-green-black",
};
