import FullFrameBulletsWithTitle from "components/templates/FullFrameBulletsWithTitle";

export const FULL_FRAME_BULLETS_WITH_TITLE_PROPS: Readonly<
  React.ComponentProps<typeof FullFrameBulletsWithTitle>
> = {
  title: "Why customer-facing roles?",
  checklists: [
    [
      {
        title:
          "Use your people skills to advance your career in high-growth roles across top companies",
      },
      {
        title:
          "SV Academy prepares you to embrace AI tools while practicing your irreplaceable human skills ",
      },
      {
        title: "Sales is #3 in Highest Paying Jobs Without A Degree",
        description: "Per U.S. News",
      },
    ],
    [
      {
        title:
          "Sales and other customer-facing roles are some of the fastest growing jobs",
        description: "Per LinkedIn Jobs on the Rise report",
      },
      {
        title: "No specific education or prior experience required",
      },
    ],
  ],
};
