import Image, { ImageProps } from "next/image";

// TODO: use https://plaiceholder.co/docs/plugins/tailwind to generate blurry placeholder images
const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#eee" offset="50%" />
      <stop stop-color="#ddd" offset="60%" />
      <stop stop-color="#eee" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#eee" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

type Props = ImageProps & {
  src: ImageProps["src"];
  alt: string;
  shimmerHeight?: number;
  shimmerWidth?: number;
};

export default function UnoptimizedImage({
  shimmerHeight = 10,
  shimmerWidth = 10,
  ...props
}: Props) {
  const type = `data:image/svg+xml;base64`;
  const data = toBase64(shimmer(shimmerWidth, shimmerHeight));

  return (
    <Image
      {...props}
      alt={props.alt}
      unoptimized
      placeholder="blur"
      blurDataURL={`${type},${data}`}
    />
  );
}
