import clsx from "clsx";
import Link from "next/link";
import router from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";

import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";
import LearnMoreCard from "components/molecules/LearnMoreCard";

import { getScreenVariant } from "lib/tailwind";

import { Sections } from "types/program.types";
import { BackgroundColors, TextColors } from "types/theme.types";

interface Props {
  className?: string;
  cards: {
    title: string;
    titleColor: TextColors;
    titleClassName?: string;
    detail: string;
    link?: string;
    bgColor: BackgroundColors;
    detailColor: TextColors;
  }[];
  bottomLine?: boolean;
  activeSection: Sections;
}

export default function SVRoadTrip(props: Props) {
  type SectionsRef = Record<Sections, HTMLDivElement>;
  const navItemsRef = useRef<SectionsRef>({} as SectionsRef);
  const scrollRef = useRef<HTMLDivElement>(null);
  const hashChangedRecently = useRef(false);
  const [scrolledHorizontally, setScrolledHorizontally] = useState(false);

  const topLineClassName = clsx(
    "before:absolute",
    "before:-top-12",
    "before:left-1/2",
    "before:border-2",
    "before:border-peach-pale",
    "before:h-12"
  );

  const bottomLineClassName = clsx(
    "after:absolute",
    "after:left-1/2",
    props.bottomLine === false ? "" : "after:border-2",
    "after:border-peach-pale",
    "md:after:-bottom-48",
    "md:after:h-48",
    "after:-bottom-32",
    "after:h-32"
  );

  const borderClassName = clsx(
    "relative",
    "border-[3px]",
    "border-peach-pale",
    "rounded-2xl",
    "px-4",
    "pt-4",
    "pb-6"
  );

  const pages = useMemo(
    () =>
      props.activeSection
        ? [
            {
              name: "Interviews",
              href: "#interviews",
              current: props.activeSection === "interviews",
            },
            {
              name: "Employer Interviews",
              href: "#employer",
              current: props.activeSection === "employer",
            },
            {
              name: "Training",
              href: "#training",
              current: props.activeSection === "training",
            },
            {
              name: "Residency",
              href: "#residency",
              current: props.activeSection === "residency",
            },
          ]
        : [],
    [props.activeSection]
  );

  const currentScrollY = useRef(0);
  const previousScrollY = useRef(0);

  useEffect(() => {
    // scroll horizontally as the user scrolls down
    if (!scrollRef.current) return;

    const scrollContainer = scrollRef.current;

    const handleScroll = () => {
      // We don't want to interfere with the browsers scrolling behavior when the user clicks on a link
      if (hashChangedRecently.current) return;

      const currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;

      previousScrollY.current = currentScrollY.current;
      currentScrollY.current = currentScroll;

      if (previousScrollY.current === 0) return;

      const isScrollingDown = currentScrollY.current >= previousScrollY.current;

      const SCROLL_PROPORTIONS: Record<Sections, number> = {
        interviews: 0.1,
        employer: 0.2,
        training: 0.3,
        residency: 0.4,
        "road-trip": 0,
      };
      const SCROLL_PROPORTION = SCROLL_PROPORTIONS[props.activeSection];
      const scrollDifference = Math.abs(
        currentScrollY.current - previousScrollY.current
      );
      const scrollDistance = scrollDifference * SCROLL_PROPORTION;
      const scrollLeft = scrollContainer.scrollLeft;

      const scrollPosition = isScrollingDown
        ? scrollLeft + scrollDistance
        : scrollLeft - scrollDistance;

      const shouldResetScroll =
        !props.activeSection || props.activeSection === "road-trip";

      if (shouldResetScroll) {
        scrollContainer?.scrollTo({
          left: isScrollingDown ? 0 : scrollContainer.clientWidth,
        });
        return;
      }

      scrollContainer?.scrollTo({
        left: scrollPosition,
      });
      setScrolledHorizontally(scrollPosition > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [props.activeSection]);

  useEffect(() => {
    const handleHashChange = () => {
      hashChangedRecently.current = true;
      setTimeout(() => {
        hashChangedRecently.current = false;
      }, 1000);
    };

    router.events.on("hashChangeStart", handleHashChange);

    return () => {
      router.events.off("hashChangeStart", handleHashChange);
    };
  }, [router.events]);

  const headerMargin = clsx(
    "transition-margin duration-100",
    scrolledHorizontally && getScreenVariant() == "mobile" ? "mx-0" : "mx-4"
  );

  return (
    <section
      id="road-trip"
      className={clsx("w-full pb-20 md:pb-28", props.className)}
    >
      <Section className="bg-sv-burgundy py-16 px-2 md:py-24">
        <div className="mx-auto md:px-11 mb-16 space-y-4">
          <Typography.H2 className="text-center text-sv-red">
            The SV Academy Program
          </Typography.H2>
          {/* <Typography.H4 className="text-center text-white mx-auto font-normal">
            Up to 4 months
          </Typography.H4>*/}
        </div>
        <div
          className={clsx(
            borderClassName,
            topLineClassName,
            bottomLineClassName
          )}
        >
          <div className="flex flex-col md:flex-row gap-4">
            {props.cards.map((card) => (
              <LearnMoreCard key={card.title} {...card} />
            ))}
          </div>
        </div>
      </Section>
      <Section
        className={clsx(
          "bg-peach-pale fixed left-0 top-0 w-full z-50 h-14 md:h-20 items-center",
          "transition-all duration-250",
          !!props.activeSection && props.activeSection !== "road-trip"
            ? "opacity-100 translate-y-0"
            : "opacity-0 -translate-y-7 pointer-events-none"
        )}
        containerMargin={headerMargin}
      >
        <div
          ref={scrollRef}
          className="flex justify-center lg:justify-between overflow-x-auto scrollbar-hide"
        >
          <Typography.H5 color="text-sv-burgundy" className="hidden lg:block">
            The SV Academy Fellowship
          </Typography.H5>

          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              {pages.map((page, idx) => (
                <li
                  ref={(el) => {
                    navItemsRef.current[page.href] = el;
                  }}
                  className="flex-[0_0_auto]"
                  key={page.name}
                >
                  <div className="flex items-center">
                    {idx !== 0 && <i className="fa-light fa-chevron-right"></i>}
                    <Link passHref={true} href={page.href}>
                      <a
                        className={clsx(
                          "ml-4 text-xl md:font-medium text-sv-burgundy",
                          idx === pages.length - 1 && "pr-4 md:pr-0",
                          page.current
                            ? "font-bold"
                            : "font-normal opacity-50 hover:opacity-100"
                        )}
                        aria-current={page.current ? "page" : undefined}
                      >
                        {page.name}
                      </a>
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </Section>
    </section>
  );
}
