import Link from "next/link";

import classnames from "lib/classnames";

import { CTA } from "types/common.types";

interface Props {
  className?: string;
  callToAction: CTA;
}

export default function CTAButton(props: Props) {
  const linkButtonClassName = props.className ?? classnames.button("primary");
  return (
    <Link passHref={true} href={props.callToAction.link}>
      <a className={linkButtonClassName}>{props.callToAction.text}</a>
    </Link>
  );
}
