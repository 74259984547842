import SVRoadTrip from "components/templates/SVRoadTrip";

export const SV_ROAD_TRIP_PROPS: Readonly<
  React.ComponentProps<typeof SVRoadTrip>
> = {
  className: "!pb-0",
  cards: [
    {
      bgColor: "bg-warm-light",
      titleColor: "text-sv-red",
      titleClassName: "font-mabry pb-2 text-center",
      detailColor: "text-green-black",
      title: "9 weeks of training",
      detail:
        "Gain the skills that matter most in today’s job market through a curriculum designed by industry experts. ",
    },
    {
      bgColor: "bg-warm-light",
      titleColor: "text-sv-red",
      titleClassName: "font-mabry pb-2 text-center",
      detailColor: "text-green-black",
      title: "3 months of job search support",
      detail:
        "Create a polished professional brand and master the interview— most grads get the job within 3 months.",
    },
    {
      bgColor: "bg-warm-light",
      titleColor: "text-sv-red",
      titleClassName: "font-mabry pb-2 text-center",
      detailColor: "text-green-black",
      title: "3 months of career coaching",
      detail:
        "Our certified professional coaches help you stand out at your new company, get promoted faster, and grow a successful career.",
    },
  ],
  activeSection: null,
  bottomLine: false,
};
